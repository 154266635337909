<template>
    <div class="mt-5" v-if="isLoadingQuiz" style="min-height:300px;">
      <LoaderMain />
    </div>
  <div v-else
    class="row position-relative m-0 p-0  w-100 big-div-quiz"
    
    style="z-index: 100"
    :style="getPageDesign()"
  >
    <div
      class="bg lazyload"
      :data-bg="MainImgSrc(getImageSrc(pageDesign.imageBlock , isDesktopView))"
      v-if="pageDesign.pageStyles.imageAlign == 'no'"
      :key="getImageSrc(pageDesign.imageBlock , isDesktopView)"
:style="
getbackgroundImage(
IsImageAdded,
pageDesign.imageBlock,
pageDesign.pageStyles.imageAlign , isDesktopView
) + getBgDivStyles()
"
    ></div>
    <!-- BackGroundDiv end-->
    <!-- Image Align Left -->
    <div
      class="px-0"
      v-show="showLeftImage"
      :class="[
        {
          'col-12 col-lg-12 col-xs-12': !isDesktopView,
          'image-division col-6': isDesktopView,
        },
      ]"
      :style="
        calculateImageWidth(
          pageDesign.imageBlock,
          isDesktopView,
          IsImageVariableWidthActive
        )
      "
    >
      <div :class="isDesktopView ? 'image-division' : ''">
        <div :style="height100(isDesktopView)">
          <LazyImage
          :key="getImageSrc(pageDesign.imageBlock , isDesktopView)"
            :style="
              getUniversalImageStyle(
                isDesktopView,
                pageDesign.imageBlock.style,
                IsImageVariableWidthActive,
                pageDesign.imageBlock
              ) +
              getImageRotation(pageDesign.imageBlock.style) +
              getImageOpacity(pageDesign.imageBlock.style)
            "
            :class="[
              `pic-${getSelectedPage.order}`,
              getImageSrc(pageDesign.imageBlock , isDesktopView) == '/images/default_start_page.png'
                ? 'default-bg'
                : '',
            ]"
            :src="MainImgSrc(getImageSrc(pageDesign.imageBlock , isDesktopView))"
            alt
          />
        </div>
      </div>
    </div>

    <!-- Main Section -->
    <!-- Main Section -->
    <div
      :class="[!IsImageAdded ? 'col-12' : imageAddedLayout]"
      class=" "
      :style="
        getMainPaddingCss(pageDesign.pageStyles, isDesktopView) +
        getScrollCSS(
          pageDesign.pageStyles,
          isDesktopView,
          pageDesign.imageBlock.style,
          IsImageVariableWidthActive,
          pageDesign.imageBlock,
          pageDesign.pageType
        ) +
        calculateContentWidth(
          pageDesign.imageBlock,
          isDesktopView,
          IsImageVariableWidthActive,
          pageDesign.pageStyles
        ) 
      "
    >
      <div
        style="
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          height: 100%;
        "
      >
        <div style="overflow: hidden scroll" class="h-100">
          <div
            v-for="(pro, ind) in pageDesign.blocksArray"
            :key="'preview' + ind"
            class=""
          >
            <div
              id="previewSinglePage"
              :style="getHeightWidth(pro.style)"
              class="content"
              style="position: relative"
              
            >
              <div
                v-if="pro.type == 'text'"
                :style="
                  getColorCss(pro.style) +
                  getPaddingCss(pro.style) +
                  getMarginCss(pro.style, isDesktopView) +
                  getColorWithOpacity(pro.style) +
                  getTextRotate(pro.style) +
                  getLineHeight(pro.style) +
                  getTextAlign(pro.style) +
                  getFontStyle(pro.style,isDesktopView)
                "
                
                v-html="getTextValue(pro.content)"
              ></div>
              <div
                v-if="pro.type == 'option'"
                
              >
                <div>
                  <!-- Radio Options -->
                  <div
                    :style="
                      getMarginCss(pro.style, isDesktopView) +
                      getTextAlign(pro.style)
                    "
                  >
                    
                  
                   
                    <div class="" >
                      <div v-if="pro.optionSetting.optionType === 'image'">
                        <div class="row">
                          <div
                            v-for="(radio, index) in getOptionsArr"
                            :key="index"
                            class="d-flex flex-column"
                            :style="
                              getDivAlignmentStyle(pro.style.textAlign) +
                              getSpaceBetweenRow(pro.style)
                            "
                            :class="
                              isDesktopView
                                ? `col-${12 / pro.OptionsPerRow}`
                                : `col-${12 / pro.OptionsPerMobile}`
                            "
                          >
                            <!-- If Image is not selected -->
                            <div
                              v-if="radio.id=='uploadNewImage'"
                              :style="
                                getImageHeightWidth(pro.style, isDesktopView)
                              "
                              class="ImageInputBlock position-relative"
                              @click.stop="openAddImageModal(radio)"
                            >
                              <LazyImage
                              :src="radio.image?radio.image:isBaldanUser?`https://cdn.shopify.com/s/files/1/0724/0574/5977/files/Capture_d_ecran_2023-12-05_a_16.34.46.png?v=1701790514`:`https://cdn.shopify.com/s/files/1/0571/0246/7222/files/Capture_d_ecran_2023-12-05_a_10.16.51.png?v=1701767843`"
                                :key="radio.image?radio.image:isBaldanUser?`https://cdn.shopify.com/s/files/1/0724/0574/5977/files/Capture_d_ecran_2023-12-05_a_16.34.46.png?v=1701790514`:`https://cdn.shopify.com/s/files/1/0571/0246/7222/files/Capture_d_ecran_2023-12-05_a_10.16.51.png?v=1701767843`"
                               
                                alt=""
                                style="
                                  cursor: pointer;
                                  height: 100%;
                                  width: 100%;
                                  object-fit: cover;
                                "
                                @click.stop="openAddImageModal(radio)"
                                :style="getBorderRadius(pro.style)"
                              />

                            <div
                                v-if="(selectedOption.image == radio.image) || (selectedOption.id=='uploadNewImage')"
                                class="SelectedImageStyle"
                                :style="getSelectedImageIconStyles(pro.style)"
                              >
                                <i
                                  class="fa fa-check-circle fa-2x"
                                  aria-hidden="true"
                                ></i>
                              </div>
                             
                              
                            </div>
                            <!-- If Image is selected -->
                            <div
                              v-else-if="radio.id!='uploadNewImage'"
                              :style="
                                getImageHeightWidth(pro.style, isDesktopView)
                              "
                              class="ImageInputBlock position-relative"
                              @click="checkSelectedImage(radio)"
                            >
                              <LazyImage
                                :src="ImageSrc(radio.image)"
                                :key="ImageSrc(radio.image)"
                                alt=""
                                @click="checkSelectedImage(radio)"
                                style="
                                  cursor: pointer;
                                  height: 100%;
                                  width: 100%;
                                "
                                :style="getBorderRadius(pro.style)"
                              />
                        
                              <div
                                v-if="(selectedOption.image == radio.image)"
                                class="SelectedImageStyle"
                                :style="getSelectedImageIconStyles(pro.style)"
                              >
                                <i
                                  class="fa fa-check-circle fa-2x"
                                  aria-hidden="true"
                                ></i>
                              </div>
                            </div>
                            <div>
                              <p
                                class="m-0 p-0 mt-2"
                                :style="
                                  getColorCss(pro.style) +
                                  getColorWithOpacity(pro.style) +
                                  getFontStyle(pro.style)
                                "
                              >
                                {{ radio.value }}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      
                    </div>
                  </div>
                </div>
              </div>
             
            </div>
          </div>
        </div>

        <!-- Single Ans Back Button -->
        <div>
          <div
           
            :style="getHeightWidth(StaticButtonElement.style)"
            class="content"
          >
            <div class="w-100">
              <div
                class="d-flex"
                :style="
                  getBackNextButtonPosition(
                    StaticButtonElement,
                    CurrentPage.value
                  )
                "
              >
                <button
                  style="z-index: 1000"
                  :style="
                    getCommonButtonCSS(
                      StaticButtonElement.style,
                      isDesktopView
                    ) +
                    getBackNextButtonCss(
                      StaticButtonElement.content.backButton.style,isDesktopView
                    )
                  "
                  @click="backPage"
                  v-if="!StaticButtonElement.content.backButton.hideBackButton"
                >
                  <span
                    :style="{
                      opacity: `${StaticButtonElement.content.backButton.style.textOpacity}%`,
                      fontSize: `${StaticButtonElement.content.backButton.style.fontSize}px`,
                    }"
                    >{{ StaticButtonElement.content.backButton.text }}</span
                  >
                </button>
                <button
                  style="z-index: 1000"
                  v-if="!StaticButtonElement.content.nextButton.hideNextButton"
                  :style="
                    getCommonButtonCSS(
                      StaticButtonElement.style,
                      isDesktopView
                    ) +
                    getBackNextButtonCss(
                      StaticButtonElement.content.nextButton.style,isDesktopView
                    )
                  "
                  @click="nextPage"
                >
                <span v-if="isLoadingNextButton">
                  <b-spinner small></b-spinner>
                </span>
                  <span v-else
                    :style="{
                      opacity: `${StaticButtonElement.content.nextButton.style.textOpacity}%`,
                      fontSize: `${StaticButtonElement.content.nextButton.style.fontSize}px`,
                    }"
                    >{{   translateCustomText(`Save`)}}</span
                  >
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Image Align Right -->
    <div
      class="px-0"
      v-show="showRightImage"
      :class="[
        {
          'col-12 col-lg-12 col-xs-12': !isDesktopView,
          'image-division col-6': isDesktopView,
        },
      ]"
      :style="
        calculateImageWidth(
          pageDesign.imageBlock,
          isDesktopView,
          IsImageVariableWidthActive
        )
      "
    >
      <div :class="isDesktopView ? 'image-division' : ''">
        <div :style="height100(isDesktopView)">
          <LazyImage
          :key="getImageSrc(pageDesign.imageBlock , isDesktopView)"
            :style="
              getUniversalImageStyle(
                isDesktopView,
                pageDesign.imageBlock.style,
                IsImageVariableWidthActive,
                pageDesign.imageBlock
              ) +
              getImageRotation(pageDesign.imageBlock.style) +
              getImageOpacity(pageDesign.imageBlock.style)
            "
            :class="[
              `pic-${getSelectedPage.order}`,
              getImageSrc(pageDesign.imageBlock , isDesktopView) == '/images/default_start_page.png'
                ? 'default-bg'
                : '',
            ]"
            :src="MainImgSrc(getImageSrc(pageDesign.imageBlock , isDesktopView))"
            alt
          />
        </div>
      </div>
    </div>

    <!-- Show Logo Footer -->
      <input
                        type="file"
                        accept="image/png,image/gif,image/jpeg,image/svg+xml"
                        style="display: none"
                        ref="UploadNewImageRef"
                       
                        @change="uploadFile"
                      />
  </div>
</template>
<script>
import TranslationMixin from "../../../customize/mixin/TranslationMixin";
import cssFunctionMixin from "../../../customize/mixin/cssFunction";
import { mapGetters, mapActions, mapMutations } from "vuex";
import axios from 'axios'
export default {
  props: [
    "isDesktopView",
    "animationType",
    "RoutePage",
    "getIsAnimationEnabled",
    "getAnimationSpeedinMillis",
    "apiToken",
  "imagePlan",
    "quizId",
    "currentQuiz",
    "selected_image"
  ],
  components:{
      LoaderMain:()=> import(/* webpackChunkName: "LoaderMain" */ '../../../../components/LoaderComps/LoaderMain.vue'),
    },

  mixins: [cssFunctionMixin, TranslationMixin],
  data() {
    return {
       uploadImageFile:null,
        selectedOption:{},
        isLoadingQuiz:true,
      
      isDiaryFairyOptionValue: false,
    isLoadingNextButton:false,
      activeSideBar: "pageStyle",
      pageDesign: {},
      count: 0,
      SelectedValue: "",
      email: "",
      name: "",
      phone: "",
      date: "",
      website: "",
      organisation: "",
      address_1: "",
      address_2: "",
      city: "",
      zip_code: "",
      state: "",
      country: "",
      delayIncrement: 0.2, // The delay between each item
    };
  },
  methods: {
    ...mapActions([
      "goToNextPreviewPage",
      "goToBackPreviewPage",
      "updateSelectedOptionsObject",
      "createEndQuizPage",
    ]),
    ...mapMutations([
      "jumpToPreviewPage",
      "PreviewPagesStack",
      "nextPageAnimation",
      "backPageAnimation",
      "setSelectedPage"
    ]),
    getPageDesign() {
      let design =
        "text-align:" +
        this.pageDesign.pageStyles.textAlign +
        ";color:" +
        this.pageDesign.pageStyles.color +
        "; background:" +
        this.pageDesign.pageStyles.backgroundColor +
        "; width:" +
        '100%'+
        "px; height:" +
        this.pageDesign.pageStyles.height +
        "px;";

      return design;
    },

    getSinglePageValues() {
      let getStartedDesignValues = {};
      if (this.currentQuiz) {
        getStartedDesignValues = this.currentQuiz.pageDesign;
      }
      return getStartedDesignValues;
    },

    async getRadioValue(value) {
        return value
      // eslint-disable-next-line no-unreachable
    //   if (value) {
    //     const logicArr = this.currentQuiz.logic || [];
    //     if (logicArr.length) {
    //       const index = logicArr.findIndex(
    //         (x) => x.optionId == value.id.toString()
    //       );
    //       if (index > -1) {
    //         this.jumpLogicFunc(logicArr, index, value);
    //       } else {
    //         const selectedOptionObj = this.createSelectedOptionObj(value);
    //         await this.updateSelectedOptionsObject(selectedOptionObj);
    //         await this.goToNextPreviewPage(this.currentQuiz.value);
    //         this.nextPageAnimation();
    //       }
    //     } else {
    //       // If no logic Array is present
    //       const selectedOptionObj = this.createSelectedOptionObj(value);
    //       await this.updateSelectedOptionsObject(selectedOptionObj);
    //       await this.goToNextPreviewPage(this.currentQuiz.value);
    //       this.nextPageAnimation();
    //     }
    //   }
    },

    async jumpLogicFunc(logicArr, index, value, contentIndex) {
      const selectedLogic = logicArr[index];
      if (selectedLogic.logicType == "jumpTo") {
        let jumpToObj = JSON.parse(selectedLogic.jumpTo);
        let noResultStatus = false;
        if (jumpToObj.value == "noResultPage") {
          this.$store.state.selectedOptions = {
            questionAnswer: [],
          };
          noResultStatus = true;
          jumpToObj.value = "resultPage";
        }
        const JumpToPreviewPageIndex = this.getTotalPreviewPages.findIndex(
          (x) => x.id == jumpToObj.id && x.value == jumpToObj.value
        );
        const selectedOptionObj = this.createSelectedOptionObj(value);
        if (contentIndex) {
          this.pageDesign.blocksArray[contentIndex].selectedOption = value;
        }
        await this.updateSelectedOptionsObject(selectedOptionObj);
        if (noResultStatus) {
          this.$store.state.selectedOptions = {
            questionAnswer: [],
          };
        }
        const currPageObj = this.currentQuiz;
        const type = "push";
        await this.PreviewPagesStack({ type, currPageObj });
        await this.jumpToPreviewPage(JumpToPreviewPageIndex);
      }
      if (selectedLogic.logicType == "redirect") {
        let URL = selectedLogic.jumpMsgOrLink;
        let pattern = /^((http|https|ftp):\/\/)/;
        if (URL) {
          if (!pattern.test(URL)) {
            URL = "http://" + URL;
          }
          // alert()
          window.open(URL);
        }
      }
      if (selectedLogic.logicType == "redirect_self") {
        let URL = selectedLogic.jumpMsgOrLink;
        let pattern = /^((http|https|ftp):\/\/)/;
        if (URL) {
          if (!pattern.test(URL)) {
            URL = "http://" + URL;
          }
          window.location.href = URL;
        }
      }
      if (selectedLogic.logicType == "endQuiz") {
        this.createEndQuizPage(selectedLogic.jumpMsgOrLink);
      }
    },
    backPage() {
      this.backPageAnimation();
      this.$emit('moveTo',1)
    },
      translateCustomText(key){
        return this.translateToItalian(key,this.quizId)   
      },
    async nextPage() {
      
    
        this.isLoadingNextButton=true;
         let data = {
        
         'image':this.selectedOption.image,
          email: localStorage.getItem("already_record_email") || this.$route.query.email,
          api_token: this.apiToken,
          questionId:this.currentQuiz.id
        };
       if(this.uploadImageFile && this.selectedOption.id=='uploadNewImage')
       {
          const form_data = new FormData()
          data.image = ''
           for (var key in data) {
                form_data.append(key, data[key]);
            }
          form_data.append('image_file', this.uploadImageFile);
          data=form_data;
       }

        await axios.post(
          `/api/custom/collection/update/image`,
          data
        ).then((response)=>{
          if(response.status==200)
          {
            this.toast('Image has been updated.')
              this.$emit('moveTo',1)
          }
          else 
          this.toast('An error has occurred')
        }).catch((error)=>{
          this.toast('An error has occurred');
          throw error})
      this.isLoadingNextButton=false;
    },
    toast(text){
      this.$toasted.show(this.translateCustomText(text), {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
    },
    async updateSelectedAnswer(value) {
      const contentIndex = this.findOptionsIndex;
      if (value) {
        const logicArr = this.currentQuiz.logic;
        if (logicArr.length) {
          const index = logicArr.findIndex(
            (x) => x.optionId == value.id.toString()
          );
          if (index > -1) {
            this.jumpLogicFunc(logicArr, index, value, contentIndex);
          } else {
            const selectedOptionObj = this.createSelectedOptionObj(value);
            this.pageDesign.blocksArray[contentIndex].selectedOption = value;
            await this.updateSelectedOptionsObject(selectedOptionObj);
            await this.goToNextPreviewPage(this.currentQuiz.value);
            this.nextPageAnimation();
          }
        } else {
          // If no logic Array is present
          const selectedOptionObj = this.createSelectedOptionObj(value);
          this.pageDesign.blocksArray[contentIndex].selectedOption = value;
          await this.updateSelectedOptionsObject(selectedOptionObj);
          await this.goToNextPreviewPage();
          this.nextPageAnimation();
        }
      }
    },
    createSelectedOptionObj(value) {
      const obj = new Object();
      obj.questionId = this.currentQuiz.id;
      obj.isMasterQuestion = this.currentQuiz.isMasterQuestion;
      obj.questionType = this.currentQuiz.value;
      obj.selectedOption = value;
      return obj;
    },
    checkSelectedImage(value) {
    //   const contentIndex = this.selectedOption;
      this.selectedOption = value;

      this.getRadioValue(value);
    },
    ImageSrc(img) {
      let newImage;
      if (img.startsWith("/http")) {
        newImage = img.substring(1);
      } else {
        newImage = img;
      }
      if (
        newImage.indexOf("http://") === 0 ||
        newImage.indexOf("https://") === 0
      ) {
        return newImage;
      } else {
        const baseURL = this.getEnv.VUE_APP_API_BASE_URL;
        return baseURL + newImage.substring(1);
      }
    },
    UpdateDynamicText() {
      if (this.getSavedLeadID.full_name !== "") {
        this.name = this.getSavedLeadID.full_name;
      }
      if (this.getSavedLeadID.email !== "") {
        this.email = this.getSavedLeadID.email;
      }
      if (this.getSavedLeadID.phone_number !== "") {
        this.phone = this.getSavedLeadID.phone_number;
      }
      if (this.getSavedLeadID.date !== "") {
        this.date = this.getSavedLeadID.date;
      }
      if (this.getSavedLeadID.website !== "") {
        this.website = this.getSavedLeadID.website;
      }
      if (this.getSavedLeadID.organisation !== "") {
        this.organisation = this.getSavedLeadID.organisation;
      }
      if (
        Object.prototype.hasOwnProperty.call(this.getSavedLeadID, "address1") &&
        this.getSavedLeadID.address1 !== ""
      ) {
        this.address_1 = this.getSavedLeadID.address1;
      }
      if (
        Object.prototype.hasOwnProperty.call(this.getSavedLeadID, "address2") &&
        this.getSavedLeadID.address2 !== ""
      ) {
        this.address_2 = this.getSavedLeadID.address2;
      }
      if (
        Object.prototype.hasOwnProperty.call(this.getSavedLeadID, "city") &&
        this.getSavedLeadID.city !== ""
      ) {
        this.city = this.getSavedLeadID.city;
      }
      if (
        Object.prototype.hasOwnProperty.call(this.getSavedLeadID, "country") &&
        this.getSavedLeadID.country !== ""
      ) {
        this.country = this.getSavedLeadID.country;
      }
      if (
        Object.prototype.hasOwnProperty.call(this.getSavedLeadID, "state") &&
        this.getSavedLeadID.state !== ""
      ) {
        this.state = this.getSavedLeadID.state;
      }
      if (
        Object.prototype.hasOwnProperty.call(this.getSavedLeadID, "zip_code") &&
        this.getSavedLeadID.zip_code !== ""
      ) {
        this.zip_code = this.getSavedLeadID.zip_code;
      }
    },
    updateSelectedImage(){
      const index = this.findOptionsIndex
      if(index>-1)
      {
        let selectedOption = this.pageDesign.blocksArray[index].selectedOption
        if(Object.keys(selectedOption).length === 0 && selectedOption.constructor === Object)
         {
          let optionIndex = this.pageDesign.blocksArray[index].options.findIndex(x=>x.image==this.selected_image)
          if(optionIndex>-1)
          {
            this.selectedOption=this.pageDesign.blocksArray[index].options[optionIndex]
          } else{
            let obj = {
              image:this.selected_image,
              id:Date.now(),value:"",detailValue:0,score_value:0

            }
              this.pageDesign.blocksArray[index].options = [obj , ...this.pageDesign.blocksArray[index].options]
                this.selectedOption=obj

          }
         }  
         else
         {
            this.selectedOption=selectedOption        
          }
      }

    // Logic to add Add new Image btn

    let uploadImageCompIndex = this.pageDesign.blocksArray[index].options.findIndex(x=>x.id == 'uploadNewImage')
    if(uploadImageCompIndex == -1){
          let addImageOptionImageObj = {
              image:'',
              id:'uploadNewImage',value:this.translateCustomText("Upload Image"),detailValue:0,score_value:0

            }
        // myArray.splice(myArray.length - 1, 0, objectToAdd);
             this.pageDesign.blocksArray[index].options  = [addImageOptionImageObj ,...this.pageDesign.blocksArray[index].options]
    }

  


    },
    openAddImageModal(){

      
      if(this.imagePlan.toLowerCase()!='free')
      this.$refs.UploadNewImageRef.click()
    else{
       this.toast('Update your plan to avail this functionality.')
    }
      // this.$refs.AddImageFileCompRef.openModalFunc();


    },
 uploadFile(e) {
    const index = this.findOptionsIndex; // Assuming this variable is defined correctly
    const file = e.target.files[0]; // Use 'e' instead of 'event'
    this.uploadImageFile = e.target.files[0]; 
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const imgUrl = reader.result; // Access imgUrl inside the onload function
        let addImageOptionImageObj = {
          image: imgUrl,
          id: 'uploadNewImage',
          value: this.translateCustomText("Upload Image"),
          detailValue: 0,
          score_value: 0
        };
        
        this.pageDesign.blocksArray[index].options.splice(0, 1, addImageOptionImageObj); 
        this.selectedOption=addImageOptionImageObj;
      };
      reader.readAsDataURL(file);
    }
  },
  // Other methods and properties...
},
  computed: {
     getEnv(){
      // ...mapGetters(["getEnvConst"]),
      // getEnvConst
      return this.getEnvConst;
    },
    StaticButtonElement() {
      const index = this.pageDesign.blocksArray.findIndex(
        (x) => x.type == "button"
      );
      return this.pageDesign.blocksArray[index];
    },
    AnimationSettings() {
      return this.getAnimationSetting;
    },
    AmimationType() {
      if (
        this.AnimationSettings &&
        typeof this.AnimationSettings !== "undefined" &&
        this.AnimationSettings.show
      ) {
        if (this.AnimationSettings.direction == "RTL") {
          if (this.animationType == "SlideIn") return "slideRTL";
          return "slideLTR";
        } else {
          if (this.animationType == "SlideIn") return "slideLTR";
          return "slideRTL";
        }
      } else {
        return null;
      }
    },
    staticButton() {
      const index = this.pageDesign.blocksArray.findIndex(
        (x) => x.type == "button"
      );
      return this.pageDesign.blocksArray[index].staticButton;
    },
    showLeftImage() {
      return (
        (this.isDesktopView &&
          this.pageDesign.imageBlock.style.displayDesktop &&
          this.pageDesign.pageStyles.imageAlign == "left") ||
        (!this.isDesktopView &&
          this.pageDesign.imageBlock.style.displayMobile &&
          (this.pageDesign.pageStyles.imageAlign == "left" ||
            this.pageDesign.pageStyles.imageAlign == "right"))
      );
    },
    showRightImage() {
      return (
        this.isDesktopView &&
        this.pageDesign.imageBlock.style.displayDesktop &&
        this.pageDesign.pageStyles.imageAlign == "right"
      );
    },
    ...mapGetters([
      "getSelectedPage",
      "getUploadedImage",
      "getTotalPages",
      "getTotalPreviewPages",
      "getPreviewSettings",
      "getPreviewProgressBar",
      "getSavedLeadID",
      "translateText",
      "getAnimationSetting",
      "getFurnitureClientCounterData",
      "getTrackIkeaClientCounter",
      "getEnvConst"
    ]),
    getSkipQuestion() {
      return this.CurrentPage.skip_status;
    },
    ProgressBarValues() {
      return this.getPreviewProgressBar;
    },
    CurrentPage() {
      return this.currentQuiz;
    },
    IsImageAdded() {
      if (this.getImageSrc(this.pageDesign.imageBlock , this.isDesktopView) !== "" || this.getUploadedImage)
        return true;
      return false;
    },
    IsImageVariableWidthActive() {
      return this.IsImageAdded && this.pageDesign.pageStyles.imageAlign != "no"
        ? true
        : false;
    },
    imageAddedLayout() {
      if (this.pageDesign.pageStyles.imageAlign == "no") return "col-12";
      if (!this.isDesktopView) return "col-12";
      return "col-12 col-xs-12 col-sm-6 col-md-6  ";
    },
    findOptionsIndex() {
      const index = this.pageDesign.blocksArray.findIndex(
        (block) => block.type == "option"
      );
      return index;
    },
    getOptionsArr() {
      if (this.findOptionsIndex) {
        if (this.isOptionsShuffled) {
          const arrayCopy = [
            ...this.pageDesign.blocksArray[this.findOptionsIndex].options,
          ];
          for (let i = arrayCopy.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [arrayCopy[i], arrayCopy[j]] = [arrayCopy[j], arrayCopy[i]];
          }

          return arrayCopy;
        } else {
          return this.pageDesign.blocksArray[this.findOptionsIndex].options;
        }
      }
      return [];
    },
    isOptionsShuffled() {
      return Object.prototype.hasOwnProperty.call(
        this.pageDesign.blocksArray[this.findOptionsIndex],
        "shuffleOptions"
      )
        ? this.pageDesign.blocksArray[this.findOptionsIndex].shuffleOptions
        : false;
    },
    isBaldanUser(){
      return this.getPreviewSettings.quizId ==  this.getEnv.VUE_APP_ITALIAN_QUIZID
    }

  
  },

  mounted() {
            // this.currentQuiz=this.question;
            this.isLoadingQuiz=false;
            // this.selectedOption= this.currentQuiz.selectedOption?
            this.updateSelectedImage();
           
    this.UpdateDynamicText();

    if (
      this.getPreviewSettings.quizId ==
      this.getEnv.VUE_APP_FURNITURE_CLIENT_QUIZID
    ) {
      // Add Counter Values To text
      let tagsQuestions = 
        this.getEnv?.VUE_APP_FURNITURE_CATEGORY_QUESTION??[]
   

      if (tagsQuestions.some((data) => data.id == this.CurrentPage.id)) {
        // Remove Selected Answer

        this.pageDesign.blocksArray[this.findOptionsIndex].selectedOption = "";
      }
    }

  },
  created() {
    this.pageDesign = this.getSinglePageValues();
  },
  watch: {
    getTotalPages(newVal, oldVal) {
      if (oldVal.length < newVal.length) {
        this.count++;
      }
      this.pageDesign = this.getSinglePageValues();
    },
    pageDesign: {
      deep: true,
      handler: function () {
        const order = this.currentQuiz.order;
        const value = this.currentQuiz.value;
        const pageDesignValue = this.pageDesign;
        this.$store.commit("setUpdatePreviewPageValues", {
          pageDesignValue,
          order,
          value,
        });
      },
    },
  },
};
</script>
<style scoped>
.alert-text {
  padding: 4px 12px !important;
  margin: 0px;
  width: fit-content;
  border-radius: 6px !important;
}

.images-size label {
  color: #b3afb6;
}

.custom-select-aanswer {
  display: flex;
  border: 1px solid #000000;
  border-radius: 6px;
  height: 36px;
  /* background:#ededee; */
  align-items: center;
}

.custom-select-aanswer img {
  width: 22px;
  height: 22px;
  position: absolute;
  left: 24px;
}

.question-type-select {
  background-color: transparent;
  border: none;
  padding: 4px 39px !important;
  height: 36px;
  width: 100%;
}

.sidebar {
  max-height: 100vh;
  overflow-y: scroll;
  scroll-behavior: smooth;
  height: 100vh;
}

.button-group {
  color: #535353;
  outline: #c4c4c4 solid 1px;
}

.button-selected {
  background: #000000 !important;
  color: #ffffff !important;
}

.text-img-btn-selected {
  background: #ffa201 !important;
  color: #ffffff !important;
}

.size-input {
  width: 60px;
  height: 20px;
  outline: #c4c4c4 solid 1px;
  border-radius: 4px;
}

.big-div-quiz {
  padding: 0px;
}

.tab-btn {
  border-radius: 4px;
  color: #042542;
  font-weight: 500;
  border: 1px solid #48cae4;
}

.tab-btn.selected {
  background: #ffa201;
}

/* DropdownStyles */
.select-div >>> .multiselect .multiselect__option--highlight,
.select-div >>> .multiselect .multiselect__option--highlight:after {
  background: var(--dropdownHoverColor) !important;
}

.radioboxDiv input[type="radio"] {
  accent-color: var(--selectedCheckboxColor);
}

.slideRTL {
  animation-name: slide-in;
  animation-duration: var(--animation-speed);
  animation-timing-function: forwards;
  animation-fill-mode: both;
}

.slideLTR {
  animation-name: slide-out;
  animation-duration: var(--animation-speed);
  animation-timing-function: forwards;
  animation-fill-mode: both;
}
</style>